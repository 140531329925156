import { Geolocation, type PermissionStatus } from "@capacitor/geolocation";
import { useModalState } from "@clipboard-health/ui-react";
import { InputChangeEventDetail } from "@ionic/core";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonInput,
  IonRow,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import { submitReferralCode } from "@src/app/referral/api";
import { OnboardingRouterPath } from "@src/app/routing/constant/onboardingRoute";
import {
  LocationPermissionDialog,
  LocationVerificationFailed,
  LocationVerificationInProgressDialog,
} from "@src/appV2/Accounts/WorkerReferrals/components/LocationVerificationStates";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { isCapacitorPlatform, useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useCbhLocationVerification } from "@src/appV2/Location/hooks";
import { useMaxReferralBonusAmount } from "@src/appV2/Referral/Bonus/flags";
import { Worker } from "@src/appV2/Worker/api/types";
import { USER_EVENTS } from "@src/constants";
import "./referralSubmission.scss";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { DefaultErrorMessage } from "./constant";

interface ReferralSubmissionProps {
  worker?: Worker;
  onNext(): void;
}

const REFERRAL_CODE_SUBMISSION_STATUS = {
  REFERRAL_CODE_SUBMIT_STARTED: "REFERRAL_CODE_SUBMIT_STARTED",
  REFERRAL_CODE_SUBMIT_FAILED: "REFERRAL_CODE_SUBMIT_FAILED",
  REFERRAL_CODE_SUBMIT_SUCCESS: "REFERRAL_CODE_SUBMIT_SUCCESS",
} as const;

export default function ReferralSubmission({ worker, onNext }: ReferralSubmissionProps) {
  const [referralCode, setReferralCode] = useState("");
  const maxReferralBonusAmount = useMaxReferralBonusAmount("onboardingBonusInDollars");
  const locationVerificationModalState = useModalState();
  const errorModalState = useModalState();
  const { showInfoToast, showErrorToast } = useToast();
  const { enableLocationVerification: locationVerificationEnabled } = useCbhFlag(
    CbhFeatureFlag.ENABLED_SIGNUP_REFERRAL_GUARD_RAILS,
    {
      defaultValue: {
        enableLocationVerification: true,
      },
    }
  );

  const handleReferralInput = (e: CustomEvent<InputChangeEventDetail>) => {
    setReferralCode(e.detail.value ?? "");
  };

  async function verifyHcpLocationAndSubmittedReferralCode() {
    let geoLocationPermission: undefined | PermissionStatus;
    try {
      geoLocationPermission = await Geolocation.checkPermissions();
    } catch (error) {
      locationVerificationModalState.openModal();
      logEvent(USER_EVENTS.REFERRAL_SUBMIT_BUTTON_CLICKED, {
        referralCodeSubmissionStatus: REFERRAL_CODE_SUBMISSION_STATUS.REFERRAL_CODE_SUBMIT_FAILED,
        referralCodeSubmissionStatusReason: `Location services disabled. ${
          (error as Error)?.message
        }`,
        referralCode,
        workerId: worker?.userId,
      });
      return;
    }

    if (geoLocationPermission.location !== "granted") {
      locationVerificationModalState.openModal();
      logEvent(USER_EVENTS.REFERRAL_SUBMIT_BUTTON_CLICKED, {
        referralCodeSubmissionStatus: REFERRAL_CODE_SUBMISSION_STATUS.REFERRAL_CODE_SUBMIT_FAILED,
        referralCodeSubmissionStatusReason: `Location permission not granted. Permission state: ${geoLocationPermission.location}`,
        referralCode,
        workerId: worker?.userId,
      });
      return;
    }
    const {
      isLocationVerified,
      error: locationVerificationError,
      distanceBetweenGeolocationsResponseInMiles,
    } = await verifyLocation();
    if (!isLocationVerified) {
      errorModalState.openModal();
      logEvent(USER_EVENTS.REFERRAL_SUBMIT_BUTTON_CLICKED, {
        referralCodeSubmissionStatus: REFERRAL_CODE_SUBMISSION_STATUS.REFERRAL_CODE_SUBMIT_FAILED,
        referralCodeSubmissionStatusReason: `Location not verified. ${
          locationVerificationError
            ? `errorMessage: ${(locationVerificationError as Error).message}`
            : ""
        }`,
        referralCode,
        workerId: worker?.userId,
        metadata: {
          geoDistance: distanceBetweenGeolocationsResponseInMiles,
        },
      });
      return;
    }
    await verifyAndSubmitReferralCode({ throwOnError: true });
  }

  const {
    isLoading: isVerifyingLocationLoading,
    mutateAsync: verifyLocation,
    reset: resetLocationVerification,
  } = useCbhLocationVerification({
    originCoordinates: {
      latitude: worker?.geoLocation?.coordinates?.[1] ?? 0,
      longitude: worker?.geoLocation?.coordinates?.[0] ?? 0,
    },
  });

  const { refetch: verifyAndSubmitReferralCode, isFetching: isSubmitting } = useQuery({
    queryKey: ["native-mobile/apply-referral-code"],
    queryFn: () => submitReferralCode(referralCode),
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      if (!response?.referralCodeValid) {
        showErrorToast(response?.message ?? DefaultErrorMessage);
        logEvent(USER_EVENTS.REFERRAL_SUBMIT_BUTTON_CLICKED, {
          referralCodeSubmissionStatus: REFERRAL_CODE_SUBMISSION_STATUS.REFERRAL_CODE_SUBMIT_FAILED,
          referralCodeSubmissionStatusReason: `Submitted code was not valid. errorMessage: ${
            response?.message ?? DefaultErrorMessage
          }`,
          referralCode,
          workerId: worker?.userId,
        });
      } else {
        showInfoToast("Referral code successfully applied.");
        logEvent(USER_EVENTS.REFERRAL_SUBMIT_BUTTON_CLICKED, {
          referralCodeSubmissionStatus:
            REFERRAL_CODE_SUBMISSION_STATUS.REFERRAL_CODE_SUBMIT_SUCCESS,
          referralCodeSubmissionStatusReason: `Successfully Submitted code. successMessage: ${
            response?.message ?? "Referral code successfully applied"
          }`,
          referralCode,
          workerId: worker?.userId,
        });
        onNext();
      }
    },
    onError: (error) => {
      showErrorToast((error as Error)?.message ?? DefaultErrorMessage);
      logEvent(USER_EVENTS.REFERRAL_SUBMIT_BUTTON_CLICKED, {
        referralCodeSubmissionStatus: REFERRAL_CODE_SUBMISSION_STATUS.REFERRAL_CODE_SUBMIT_FAILED,
        referralCodeSubmissionStatusReason: `Submitted code was not valid. errorMessage: ${
          (error as Error)?.message ?? DefaultErrorMessage
        }`,
        referralCode,
        workerId: worker?.userId,
      });
    },
  });
  useEffect(() => {
    if (worker?.userId) {
      logEvent(USER_EVENTS.REFERRAL_SUBMISSION_PAGE_VIEWED, {
        workerId: worker?.userId,
      });
    }
  }, [worker?.userId]);

  async function handleSubmitReferralSubmission() {
    logEvent(USER_EVENTS.REFERRAL_SUBMIT_BUTTON_CLICKED, {
      referralCodeSubmissionStatus: REFERRAL_CODE_SUBMISSION_STATUS.REFERRAL_CODE_SUBMIT_STARTED,
      referralCode,
      workerId: worker?.userId,
    });
    if (!referralCode || referralCode?.length < 8) {
      showErrorToast("Please Enter Valid Referral Code");
      logEvent(USER_EVENTS.REFERRAL_SUBMIT_BUTTON_CLICKED, {
        referralCodeSubmissionStatus: REFERRAL_CODE_SUBMISSION_STATUS.REFERRAL_CODE_SUBMIT_FAILED,
        referralCodeSubmissionStatusReason:
          "Submitted code was is invalid as it does not have required length",
        referralCode,
        workerId: worker?.userId,
      });
      return;
    }

    if (!locationVerificationEnabled) {
      await verifyAndSubmitReferralCode({ throwOnError: true });
      return;
    }
    await verifyHcpLocationAndSubmittedReferralCode();
  }

  return (
    <>
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref={OnboardingRouterPath.ONBOARDING_STRIPE}
              mode="ios"
              color="dark"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <div className="referral-submission content-layout">
          <div className="form-container">
            <IonRow>
              <IonCol>
                <div className="form-heading">
                  <h4>Referred by a friend?</h4>
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="form-content">
                <b>Enter your referral code here. You can earn ${maxReferralBonusAmount}.</b>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="referral-input-container">
                <IonInput
                  value={referralCode}
                  placeholder="XXXXXX"
                  inputmode="text"
                  maxlength={8}
                  type="text"
                  onIonChange={handleReferralInput}
                  className="referral-code-input"
                ></IonInput>
              </IonCol>
            </IonRow>
          </div>
          <div className="signupform-footer footer-container">
            <IonButton
              expand="block"
              size="large"
              class="ion-margin-top ion-margin-bottom continue-button"
              onClick={handleSubmitReferralSubmission}
              disabled={isSubmitting}
              id="verify-button"
            >
              Continue
              {isSubmitting && <IonSpinner slot="end" class="ion-margin-start" name="lines" />}
            </IonButton>
            <IonButton
              color="light"
              mode="ios"
              expand="block"
              className="secondary-action"
              onClick={onNext}
            >
              I don't have a referral code
            </IonButton>
          </div>
        </div>
        <LocationPermissionDialog
          modalState={locationVerificationModalState}
          onContinue={async () => {
            if (isCapacitorPlatform()) {
              try {
                await Geolocation.requestPermissions({
                  permissions: ["location"],
                });
              } catch (error) {
                logEvent(USER_EVENTS.REFERRAL_SUBMIT_BUTTON_CLICKED, {
                  referralCodeSubmissionStatus:
                    REFERRAL_CODE_SUBMISSION_STATUS.REFERRAL_CODE_SUBMIT_FAILED,
                  referralCodeSubmissionStatusReason: `Location services disabled. ${
                    (error as Error)?.message
                  }`,
                  referralCode,
                  workerId: worker?.userId,
                });
              }
            }
            await verifyHcpLocationAndSubmittedReferralCode();
          }}
        />
        <LocationVerificationInProgressDialog isLoading={isVerifyingLocationLoading} />
        <LocationVerificationFailed
          modalState={errorModalState}
          resetLocationVerification={resetLocationVerification}
        />
      </IonContent>
    </>
  );
}
